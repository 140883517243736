//Dashboard.js
import React, { useState, useEffect } from 'react';

import PlaylistNameInput from './PlaylistNameInput';
import SongsSlider from './SongsSlider';
import PopularitySlider from './PopularitySlider';
import fetchSpotifyRecommendations from './SpotifyPlaylist';

import { useAuth } from './AuthContext';
import './App.css';

function Dashboard() {
  // States for sliders and playlist name
  const [songsValue, setSongsValue] = useState(50);
  const [popularityValue, setPopularityValue] = useState(50);
  const [playlistName, setPlaylistName] = useState('Discover Daily');
  // Original List for drop downs
  const [artists, setArtists] = useState([]);
  const [genres, setGenres] = useState([]);
  // For auto complete
  const [artistInput, setArtistInput] = useState('');
  const [genreInput, setGenreInput] = useState('');
  //For selected list to be used in Recs
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  // Token and Loading States
  const { accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUserId = async () => {
    const response = await fetch('https://api.spotify.com/v1/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    const userData = await response.json();
    return userData.id;
  };

  useEffect(() => {
    const fetchLikedTracks = async () => {
      if (!accessToken) return;
  
      try {
        const allTracks = [];
        const uniqueArtistIds = new Set();
  
        for (let offset = 0; offset < 100; offset += 50) {
          const response = await fetch(`https://api.spotify.com/v1/me/tracks?limit=50&offset=${offset}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          allTracks.push(...data.items);
  
          // Extract unique artists
          data.items.forEach(track => {
            track.track.artists.forEach(artist => {
              uniqueArtistIds.add(artist.id);
            });
          });
        }
  
        // Fetch artist names and genres
        const fetchedArtists = [];
        const genres = new Set();
        
        for (const artistId of uniqueArtistIds) {
          const artistResponse = await fetch(`https://api.spotify.com/v1/artists/${artistId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          const artistData = await artistResponse.json();
          fetchedArtists.push({ id: artistData.id, name: artistData.name });
          artistData.genres.forEach(genre => genres.add(genre));
        }
  
        setArtists(fetchedArtists); // Now storing objects with id and name
        setGenres(Array.from(genres));
      } catch (error) {
        console.error('Failed to fetch liked tracks:', error);
      }
    };
  
    fetchLikedTracks();
  }, [accessToken]);

  const handleCreatePlaylist = () => {
    setIsLoading(true);
    setError(null);

    fetchUserId()
      .then(userId => {
        // Call the function to create the playlist with the fetched user ID
        return fetchSpotifyRecommendations(selectedArtists, selectedGenres, songsValue, popularityValue, accessToken, setIsLoading, userId, playlistName);
      })
      .then(playlistData => {
        console.log('Playlist created:', playlistData);
        // Handle the newly created playlist data here
      })
      .catch(err => {
        console.error('Error:', err);
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handlers for slider and text input changes
  const filteredArtists = artists.filter(artist => 
    artist.name.toLowerCase().includes(artistInput.toLowerCase())
  );
  const filteredGenres = genres.filter(genre => 
    genre.toLowerCase().includes(genreInput.toLowerCase())
  );

  const selectArtist = (artist) => {
    if (!selectedArtists.some(a => a.id === artist.id)) {
      setSelectedArtists([...selectedArtists, artist]);
    }
    setArtistInput(''); // Clear the input field
  };
  
  const selectGenre = (genre) => {
    if (!selectedGenres.includes(genre)) {
      setSelectedGenres([...selectedGenres, genre]);
    }
    setGenreInput(''); // Clear the input field
  };

  const removeArtist = (artistId) => {
    setSelectedArtists(prevArtists => prevArtists.filter(artist => artist.id !== artistId));
  };
  
  const removeGenre = (genre) => {
    setSelectedGenres(prevGenres => prevGenres.filter(g => g !== genre));
  };

  return (
    <div className="App-header">
        <p className="spotify-playlist-header">Spotify Generative Playlist Creation</p>
        <PlaylistNameInput 
          playlistName={playlistName} 
          setPlaylistName={setPlaylistName} 
        />
        <SongsSlider 
          songsValue={songsValue} 
          setSongsValue={setSongsValue} 
        />
        <PopularitySlider 
          popularityValue={popularityValue} 
          setPopularityValue={setPopularityValue} 
        />

        {/* Dropdown Container */}
        <div className="dropdown-container">
            {/* Artist Autocomplete */}
            <div className="label-input-group">
                <label htmlFor="artist-input">Select Artist</label>
                <input
                    type="text"
                    id="artist-input"
                    value={artistInput}
                    onChange={(e) => setArtistInput(e.target.value)}
                    placeholder="Type artist name"
                />
                {artistInput && (
                    <ul className="dropdown-list">
                    {filteredArtists.map(artist => (
                        <li
                        key={artist.id}
                        tabIndex={0}
                        onClick={() => selectArtist(artist)}
                        onKeyDown={(e) => e.key === 'Enter' && selectArtist(artist)}
                        >
                        {artist.name}
                        </li>
                    ))}
                    </ul>
                )}
            </div>

            {/* Genre Autocomplete */}
            <div className="label-input-group">
                <label htmlFor="genre-input">Select Genre</label>
                <input
                    type="text"
                    id="genre-input"
                    value={genreInput}
                    onChange={(e) => setGenreInput(e.target.value)}
                    placeholder="Type genre"
                />
                {genreInput && (
                    <ul className="dropdown-list">
                    {filteredGenres.map(genre => (
                        <li
                        key={genre}
                        tabIndex={0} // Make the li focusable
                        onClick={() => selectGenre(genre)}
                        onKeyDown={(e) => e.key === 'Enter' && selectGenre(genre)}
                        >
                        {genre}
                        </li>
                    ))}
                    </ul>
                )}
            </div>
        </div>

        <div className="dropdown-container">
            <div className="selected-artists">
                <ul>
                {selectedArtists.map(artist => (
                    <li key={artist.id}>
                    {artist.name}
                    <button onClick={() => removeArtist(artist.id)}>Remove</button>
                    </li>
                ))}
                </ul>
            </div>

            <div className="selected-genres">
                <ul>
                {selectedGenres.map((genre, index) => (
                    <li key={index}>
                    {genre}
                    <button onClick={() => removeGenre(genre)}>Remove</button>
                    </li>
                ))}
                </ul>
            </div>
        </div>

        <div className="make-playlist">
            <button onClick={handleCreatePlaylist} disabled={isLoading}>
                {isLoading ? 'Creating...' : 'Make my Playlist'}
            </button>
            {error && <p>Error: {error}</p>}
        </div>
    </div>
  );
}

export default Dashboard;