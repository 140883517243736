// SpotifyPlaylist.js
const fetchSpotifyRecommendations = async (selectedArtists, selectedGenres, songsValue, popularityValue, accessToken, setIsLoading, userId, playlistName) => {
    try {
      setIsLoading(true); // Start loading
      
      // Ensure there's at least one artist or genre selected
      if (selectedArtists.length === 0 && selectedGenres.length === 0) {
        console.log("Select at least one artist or genre");
        return;
      }
  
      // Fetch recommendations
      const artistIds = selectedArtists.map(artist => artist.id).join(',');
      const genres = selectedGenres.join(',');
      const recommendationsUrl = `https://api.spotify.com/v1/recommendations?limit=${songsValue}&market=US&seed_artists=${artistIds}&seed_genres=${genres}&target_popularity=${popularityValue}`;
  
      const recommendationsResponse = await fetch(recommendationsUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      const recommendationsData = await recommendationsResponse.json();
  
      // Create a new playlist
      const createPlaylistUrl = `https://api.spotify.com/v1/users/${userId}/playlists`;
      const playlistResponse = await fetch(createPlaylistUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: playlistName }),
      });
  
      const playlistData = await playlistResponse.json();
      const playlistId = playlistData.id;
  
      // Add tracks to the playlist
      const trackUris = recommendationsData.tracks.map(track => track.uri);
      const addTracksUrl = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
      await fetch(addTracksUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uris: trackUris }),
      });
  
      setIsLoading(false); // End loading
      return playlistData; // Return the new playlist data
    } catch (error) {
      console.error('Error in Spotify operations:', error);
      setIsLoading(false);
      throw error;
    }
  };
  
  export default fetchSpotifyRecommendations; 