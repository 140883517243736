// Callback.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the useAuth hook
import './App.css';

function Callback() {
    const navigate = useNavigate();
    const { setAccessToken } = useAuth(); // Use the context

    useEffect(() => {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.substring(1));
        const accessToken = params.get('access_token');

        if (accessToken) {
            setAccessToken(accessToken); // Update the token in context using setAccessToken
            navigate('/dashboard');
        }
    }, [navigate, setAccessToken]); // setAccessToken is used here

    return <div className='App-header'>Loading...</div>;
}

export default Callback;