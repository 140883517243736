// SongsSlider.js
import React from 'react';

function SongsSlider({ songsValue, setSongsValue }) {
  const handleSongsSliderChange = (event) => {
    setSongsValue(event.target.value);
  };

  return (
    <div className="label-input-group">
        <label htmlFor="songs-slider">Number of Songs (1-100): {songsValue}</label>
        <input
          type="range"
          id="songs-slider"
          className="range-slider"
          min="1"
          max="100"
          value={songsValue}
          onChange={handleSongsSliderChange}
        />
    </div>
  );
}

export default SongsSlider;