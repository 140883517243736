// PopularitySlider.js
import React from 'react';

function PopularitySlider({ popularityValue, setPopularityValue }) {
  const handlePopularitySliderChange = (event) => {
    setPopularityValue(event.target.value);
  };

  return (
    <div className="label-input-group">
        <label htmlFor="popularity-slider">Artist Popularity (1-100): {popularityValue}</label>
        <input
          type="range"
          id="popularity-slider"
          className="range-slider"
          min="1"
          max="100"
          value={popularityValue}
          onChange={handlePopularitySliderChange}
        />
    </div>
  );
}

export default PopularitySlider;